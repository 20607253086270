import React from "react"
import {
  StyledProductList,
  StyledProductListItem,
} from "./Products/productArchive"
import GiftCard from "./Products/GiftCard"

const GiftList = ({ posts, pageType, padding }) => {
  
  return (
    <StyledProductList pageType={pageType} padding={padding}>
      {posts.map((post, index) => {
          return(
            <>
            <StyledProductListItem type="gifts">
              <GiftCard
                  title={post.title}
                  id={post.id}
                  excerpt={post.excerpt}
                  featuredImage={post.image}
                  stripefullprice={post.stripeFullPrice}
                  stripesaleprice={post.stripeSalePrice}
                  type={post.type}
                />  
                
            </StyledProductListItem>
            </>
          )
      })}
    </StyledProductList>
  )
}

export default GiftList
