import React, { useState } from 'react'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { EventListItem, EventPrice } from "../../styledComponents/eventsList"
import { StyledAddToCartButton } from "../../styledComponents/button"


const SkuCardDistanceHealing = ({ sku, type, products }) => {
  const [isFullyBooked, setIsFullyBooked] = useState(false) // manage is success message state
  const { checkoutSingleItem } = useShoppingCart()
  const programPrice = formatCurrencyString({value: parseInt(sku.price),currency: sku.currency,})
  const fullPrice = sku.price
  
  const re = /\b(\d+)(\d{2})\b/
  const fp = sku.price
  const subst = '$1.$2'
  const priceWithDecimal = fp.toString().replace(re, subst)
  const mindfuleMeditations = products
  return (
    <EventListItem className={type == 'gift' && 'gift-item'}>
      <h3>{isFullyBooked && <span className="fully-booked">Fully booked </span>} {sku.name}</h3>
      {!isFullyBooked && sku.dates && <p className="event-dates">Then on the following: <strong>{sku.dates}</strong></p>}
      {sku.description && <p className="product-description">{sku.description}</p>}
      {/* {mindfuleMeditations &&  
        mindfuleMeditations.edges.map((meditation, index) => {

          const mindfuleMeditationImage = {
            imageData: meditation.node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
            alt: meditation.node.featuredImage?.node?.alt || ``,
            description: meditation.node.featuredImage?.node?.description || "",
          }
          return (
            <figure>
              <GatsbyImage
              image={mindfuleMeditationImage.imageData}
              alt={mindfuleMeditationImage.alt}
              width="100%"
              padding-bottom="0" />
              <caption>{meditation.node.title}</caption>
          </figure>
          )
        })
      }
      {sku.image && <img src={sku.image} />} */}
      <EventPrice>
        <strong>{sku.discount && 'Earlybird '}Price: </strong>
        {formatCurrencyString({
          value: parseInt(sku.price),
          currency: sku.currency,
        })}
        {sku.discount &&  <span> save {sku.discount}%</span>}
      </EventPrice>
      <StyledAddToCartButton 
        disabled={isFullyBooked}
        onClick={() => {
            checkoutSingleItem({sku: sku.sku})
          }
        }
      >{type == 'gift' ? 'Buy now' : 'Book now'}
      </StyledAddToCartButton>
    </EventListItem>
  )
}

export default SkuCardDistanceHealing