const fixture = [
    {
      title: "Gifts",
      featuredImage: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
      gifts: [
        {
            title: "Quantum-Touch healing session",
            excerpt: "<p>Show someone you care with a Quantum-Touch healing session. A truely wonderful gift for a loved one to help with aches, pains, a chakra balancing session or anything they wish to focus on.</p>",
            image: "/images/gift-healing-session.jpg",
            stripeFullPrice: "price_1M2yS0IfnzsCIRSO6kYYltCI",
            id: "gift-healing-session",
            type: "healing-session"
        },
        {
            title: "Workshop ticket",
            excerpt: "<p>Teach someone how to literally change their life and health with a ticket to a Quantum-Touch Level 1 workshop. This is a transformational gift that lasts forever!</p>",
            image: "/images/gift-quantum-touch-workshop.jpg",
            stripeFullPrice: "price_1JpYCTIfnzsCIRSOY6oBLyLB",
            id: "gift-workshop-ticket",
            type: "workshop"
        },
        {
          title: "The Essential Mindfulness Collection",
          excerpt: "<p>This collection has a mindful meditation to suit each intention and bring you back to the present moment, with a meditation to suit different times of the day. Choose from a short morning audio to a 7 minute lunchtime reset - there is an audio to suit your every mood.</p>",
          image: "/images/the-essential-mindfulness-collection.jpg",
          stripeFullPrice: "price_1M2ySxIfnzsCIRSOWml9O2QV",
          // stripeSalePrice: "price_1JpYixIfnzsCIRSO86uHUUHL",
          id: "gift-mindfulness-meditation",
          type: "gift-mindfulness-meditation"
        },
        {
          title: "Chakra Balancing collection",
          excerpt: "<p>7 individual meditations for each of the chakras with an additional bonus meditation that includes all 7 chakras.</p><p>When our chakras are balanced we feel grounded, alert and in a state of flow in all areas of life.</p>",
          image: "/images/chakra-balancing-collection.png",
          stripeFullPrice: "price_1Jv0hMIfnzsCIRSO1qFFfOQO",
          stripeSalePrice: "price_1Jv0hMIfnzsCIRSO1qFFfOQO",
          id: "gift-chakra-balancing-collection",
          type: "gift-chakra-balancing-collection"
      },
      
      ],
    }
  ]
  
  export default fixture
  




// sound-bell.jpg