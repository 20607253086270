import React, { useState } from "react"
import { useShoppingCart } from 'use-shopping-cart'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import GiftCardPurchase from "./GiftCardPurchase"

const GiftCard = props => {
  const identity = useIdentityContext()
  const [processing, setProcessing] = useState(false);
  const [user, setUser] = useState(false);
  const { addItem } = useShoppingCart()
  const { 
    title, 
    id,
    featuredImage, 
    uri, 
    type,
    excerpt, 
    pricefull, 
    pricesale, 
    sku,
    stripefullprice,
    stripesaleprice
  } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  return (
     <><GiftCardPurchase data={props} /></>
  );
}

export default GiftCard
