import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SkuCardDistanceHealing from './SkuCardDistanceHealing'
import { EventList } from "../../styledComponents/eventsList"

const SkuChakraBalancingCollectionGift = (props) => {
  const { prices, products } = useStaticQuery(graphql`
    query SkuChakraBalancingCollectionGiftQuery {
      prices: allStripePrice(
        filter: {
          active: {eq: true}, 
          currency: {eq: "gbp"}, 
          product: {active: {eq: true}, metadata: {type: {eq: "gift-chakra-balancing-collection"}}}
        }, sort: {fields: id, order: ASC}
      ) {
        edges {
          node {
            id
            active
            currency
            unit_amount
            product {
              id
              name
              active
              description
              metadata {
                type
              }
            }
          }
        }
      },
      
      products: allWpProduct(filter: {id: {eq: "cG9zdDo2OTcx"}}) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    }
  `)

  return (
    
    <EventList>
      {prices.edges.map(({ node: price }) => {
        const newSku = {
          sku: price.id,
          name: price.product.name,
          price: price.unit_amount,
          currency: price.currency,
          description: price.product.description,
          image: price.product.images
        }
        return <SkuCardDistanceHealing key={price.id} sku={newSku} type="gift" products={products}/>
      })}
    </EventList>
  )
}

export default SkuChakraBalancingCollectionGift
