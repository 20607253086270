import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SkuCardDistanceHealing from './SkuCardDistanceHealing'
import { EventList } from "../../styledComponents/eventsList"

const SkuDistanceHealingGift = (props) => {
  const { prices } = useStaticQuery(graphql`
    query SkuDistanceHealingGiftQuery {
      prices: allStripePrice(
        filter: {
          active: {eq: true}, 
          currency: {eq: "gbp"}, 
          product: {active: {eq: true}, metadata: {type: {eq: "gift-distance-healing"}}}
        }, sort: {fields: id, order: ASC}
      ) {
        edges {
          node {
            id
            active
            currency
            unit_amount
            product {
              id
              name
              active
              description
              metadata {
                type
              }
            }
          }
        }
      }
    }
  `)

  return (
    <EventList>
      {prices.edges.map(({ node: price }) => {
        const newSku = {
          sku: price.id,
          name: price.product.name,
          price: price.unit_amount,
          currency: price.currency,
          description: price.product.description,
          image: price.product.images
        }
        return <SkuCardDistanceHealing key={price.id} sku={newSku} type="gift"/>
      })}
    </EventList>
  )
}

export default SkuDistanceHealingGift
