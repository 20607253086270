import React, { useState } from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { useShoppingCart } from 'use-shopping-cart'
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { StyledModal } from "../../styledComponents/modal"
import Icon from "../../components/icon"
import lunchtimeReset from "../../fixtures/mindfulness-collection-lunchtime-reset"
import SkuDistanceHealingGift from "../../components/Products/SkuDistanceHealingGift"
import SkuWorkshopGift from "../../components/Products/SkuWorkshopGift"
import SkuMindfulnessMeditationGift from "./SkuMindfulnessMeditationGift"
import SkuChakraBalancingCollectionGift from "./SkuChakraBalancingCollectionGift"

import Logo from "../../components/logo"
import {
  StyledProductPrimaryCard,
  ProductHeader,
  ProductExcerpt,
  ProductFigure
} from "./StyledPrimaryProductCard"

import { StyledButton, StyledCloseModalButton } from "../../styledComponents/button"
function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }
  

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  const collection = lunchtimeReset 

let skuList
if(data.data.type === 'healing-session') {
  skuList = <SkuDistanceHealingGift />
} else if(data.data.type === 'workshop') {
  skuList = <SkuWorkshopGift />
} else if(data.data.type === 'gift-mindfulness-meditation') {
  skuList = <SkuMindfulnessMeditationGift />
} else if(data.data.type === 'gift-chakra-balancing-collection') {
  skuList = <SkuChakraBalancingCollectionGift />
}

return (
    <>
      <StyledButton onClick={toggleModal}>Gift this</StyledButton>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
          className={`order-modal gift-modal ${data.data.type}`}
        >
          <StyledCloseModalButton onClick={toggleModal}>
            <Icon name="cross" />
          </StyledCloseModalButton>
          <h2><Logo /></h2>
          {skuList}
        </StyledModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const GiftCardPurchase = props => {
    const { 
        title, 
        description,
        id,
        featuredImage, 
        type,
        uri, 
        excerpt, 
        pricefull, 
        pricesale, 
        sku,
        stripefullprice,
        stripesaleprice
      } = props

      const { addItem, checkoutSingleItem } = useShoppingCart()
      const image = {
        imageData: props.data.featuredImage,
        alt: ``,
      }
    return (
    <StyledProductPrimaryCard className="gift-item">
      <ProductFigure>
        <img
          src={props.data.featuredImage}
          width="100%"
          padding-bottom="0" />
      </ProductFigure>
      <ProductHeader>{props.data.title}</ProductHeader>
      <ProductExcerpt>{parse(props.data.excerpt)}</ProductExcerpt>
      <ModalProvider backgroundComponent={FadingBackground}>
        <FancyModalButton data={props.data} />
      </ModalProvider>
    </StyledProductPrimaryCard>

    )
}
export default GiftCardPurchase